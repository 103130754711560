<template>
  <base-page
    :title="$t('biManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.biFindList"
      :multiple="false"
      :highlightCurrentRow="false"
      :columns="columns"
      :defaultParams="params"
      v-model="tableRow"
    >
      <!-- 所属企业 -->
      <template v-slot:company="{ row }">
        {{ row.companyData.name }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        <span v-if="row.enable === 'NORMAL'" style="color: #2fdd60">
          {{ enableEnumObj[row.enable] }}
        </span>
        <span v-else style="color: #ff8a2b">
          {{ enableEnumObj[row.enable] }}
        </span>
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:修改 -->
        <el-button
          v-if="$page.showBtn('BI_MANAGE_UPDATE')"
          type="text"
          @click="editMethod(row)"
        >
          {{ $t('functionEnum.BI_MANAGE_UPDATE') }}
        </el-button>
        <!-- lang:删除 -->
        <el-button
            v-if="$page.showBtn('BI_MANAGE_DELETE')"
            type="text"
            @click="deleteMethod(row)"
        >
          {{ $t('functionEnum.BI_MANAGE_DELETE') }}
        </el-button>
        <!-- lang:禁用 -->
        <el-button
            v-if="$page.showBtn('BI_MANAGE_DISABLE')"
            type="text"
            @click="disableMethod(row)"
        >
          {{ $t('functionEnum.BI_MANAGE_DISABLE') }}
        </el-button>
        <!-- lang:恢复 -->
        <el-button
            v-if="$page.showBtn('BI_MANAGE_RECOVER')"
            type="text"
            @click="recoverMethod(row)"
        >
          {{ $t('functionEnum.BI_MANAGE_RECOVER') }}
        </el-button>
      </template>
    </table-list>
     <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-import
        v-if="showDialogImport"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.templateImport"
        :show-tips="false"
        :accept="'.template'"
        @cancel="showDialogImport = false"
        @confirm="importConfirm"
    />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      // 显示表单
      showDetail:false,
      showDialogImport:false,
      // 表格页
      tableRow: null,
      tableRows: [],
      config: [],
      params: {
        // companyUid: this.$store.getters.company.uid,
        enableValues: ['DISABLE', 'NORMAL']
      },
      columns: [
        // lang:模板名称
        {
          tag: 'slot-content',
          label: this.$t('biManage.targetCompany'),
          prop: 'company',
        },
        // lang:产品版本
        {
          label: this.$t('keyDistribution.versionType'),
          prop: 'versionType',
          hidden: true,
          callback(row) {
            const version = _that.$dict.workflowProductVersion().filter(item => item.value === row.versionType)
            if (version.length > 0) {
              return version[0].label
            }
            return ''
          }
        },
        // lang:大屏地址
        {
          label: this.$t('biManage.biUrl'),
          prop: 'biUrl',
        },
        // lang: 状态
        {
          label: this.$t('biManage.status'),
          prop: 'enable',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '200px',
        },
      ],
      enableEnumObj: this.$util.listToObj(this.$dict.enable()),
    }
  },
  methods: {
    // 关闭详情页回调
    closePage(cmd) {
      this.showDetail = false
      this.showRenewal = false
      this.search()
    },
     // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && this.tableRows.length === 0) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        default: // do something
      }
    },
    editMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'update'
      this.showDetail = true
    },
    deleteMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.biDel,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
    disableMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.disableConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.biDisable,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.disableSuccess'), 'success')
              this.search()
            })
          })
    },
    recoverMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.recoverConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.biRecover,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.recoverSuccess'), 'success')
              this.search()
            })
          })
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
  },
}
</script>
